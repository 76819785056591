import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import image from 'images/4w1hog.webp';

import colors from 'lib/colors/minimal';
import { useTypography } from 'providers/typography';
import Section, { BackgroundColor, TextAlign } from 'components/section';
import Separator from 'components/separator';
import Image from 'components/image';
import ArticlesList from 'components/articlesList';
import Avatar from 'components/avatar';
import AboutMe from 'components/aboutMe';
import Note from 'components/note';
import Link from 'components/link';
import * as Anchor from 'components/anchor';
import FancyQuote from 'components/fancyQuote';
import Email from 'components/email';
import Drawer, {
  DrawerContainer,
  DrawerControl,
  DrawerEvent,
} from 'components/drawer';

const Headline = styled.h3`
  ${({ theme: { width } }) => `
    margin: auto;
    text-align: center;
    @media (min-width: ${width}px) {
      width: 80%;
    }
  `}
`;

const CenterOnNarrowScreen = styled.div`
  ${({ theme: { width } }) => `
    @media (max-width: ${width}px) {
      text-align: center;
    }
    + * {
      @media (max-width: ${width}px) {
        text-align: center;
      }
    }
  `}
`;

const LiBar = styled.li<{ $width: number }>`
  list-style: none;
  margin-left: 0;
  position: relative;
  padding-bottom: 5px;

  ::before {
    background: rgb(65, 105, 225);
    bottom: 4px;
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    width: ${({ $width }) => $width * 2 || 10}%;
  }

  ::after {
    content: '${({ $width }) => $width}%';
    display: block;
    font-weight: 600;
    position: absolute;
    right: 0;
    bottom: 6px;
    text-align: right;
  }
`;

function Page() {
  const { rhythm } = useTypography();
  const drawerEvent1 = new DrawerEvent();
  return (
    <>
      <Helmet>
        <title>Jonathan Experton - Technical advisor, team leader</title>
        <meta
          property="description"
          content="I help companies to start, plan and execute software development projects."
        />
        <meta property="og:image" content={image} />
        <meta
          property="og:description"
          content="I help companies to start, plan and execute software development projects."
        />
      </Helmet>
      <Section>
        <Avatar>
          <h1 style={{ marginTop: rhythm(1) }}>
            Hi, my name is Jonathan&nbsp;Experton
          </h1>
          <h2 style={{ marginTop: 0 }}>
            I&apos;m a{' '}
            <span style={{ color: colors.C5 }}>technical&nbsp;director</span>{' '}
            and&nbsp;
            <span style={{ color: colors.C5 }}>
              information technology advisor
            </span>
          </h2>
          <p>
            I help companies start, plan and execute{' '}
            <strong>software development projects</strong> to deliver on time,
            on scope and on budget.
          </p>
        </Avatar>
      </Section>
      <Section background={BackgroundColor.DARK}>
        <h2>
          Are you starting a{' '}
          <span style={{ color: colors.C5 }}>software development project</span>
          ?
        </h2>
        <p>
          Then, you probably already know what you want. Maybe you already have
          a launch date in mind.
        </p>
        <p>
          You can easily communicate your vision, tell why it is the right thing
          to build, and why now.
          <Anchor.Note id="1" />
        </p>
        <p>There are some facts you should know, though.</p>
        <Drawer
          header={
            <p style={{ marginBottom: 0 }}>
              🥱{' '}
              <span className="underline">
                I&apos;m busy, show me the short version.
              </span>
            </p>
          }
        >
          <>
            <h3>What can I do for you?</h3>
            <ul>
              <li>
                <strong>To start the project:</strong>
                <br />
                Direct business requirements gathering, estimate features, and
                design a technical solution.
              </li>
              <li>
                <strong>During the project planning phase:</strong>
                <br />
                Build the project roadmap and the production planning,
                anticipate issues and dependencies.
              </li>
              <li>
                <strong>During the project execution phase:</strong>
                <br />
                Set up a software development life cycle, document and teach
                such a process to the development team.
              </li>
            </ul>
            <Image alt="How when who" src="4w1h.webp" width={50} />
          </>
        </Drawer>
      </Section>
      <Section>
        <h2>Most organizations fail at delivering IT projects</h2>
        <p>What am I talking about?</p>
        <FancyQuote
          author="Susan Moore"
          cite={
            <>
              <Link to="https://www.gartner.com/smarterwithgartner/it-projects-need-less-complexity-not-more-governance#:~:text=Despite%20more%20than,projects%20keep%20failing">
                IT Projects Need Less Complexity, Not More Governance
              </Link>
              . Gartner. 2015
            </>
          }
        >
          Despite more than 50 years of history and countless methodologies,
          advice and books, IT projects keep failing.
        </FancyQuote>
        <p>Yes, IT projects often fail:</p>
        <FancyQuote
          author="Keith Ellis"
          cite={
            <>
              <Link to="https://web.archive.org/web/20130917021904/http://www.iag.biz/images/resources/iag%20business%20analysis%20benchmark%20-%20full%20report.pdf">
                Business Analysis Benchmark.
              </Link>{' '}
              IAG, 2008
            </>
          }
        >
          68% of companies are unlikely to have successful projects.
        </FancyQuote>
        <Drawer
          header={
            <p>
              🤔{' '}
              <span className="underline">
                It sounds pessimistic. Tell me more...
              </span>
            </p>
          }
        >
          <>
            <FancyQuote
              author="Patrick Forth, Tom Reichert, Romain de Laubier, and Saibal Chakraborty"
              cite={
                <>
                  <Link to="https://www.bcg.com/publications/2020/increasing-odds-of-success-in-digital-transformation">
                    Flipping the Odds of Digital Transformation Success
                  </Link>
                  . BCG, 2020
                </>
              }
            >
              70% of digital transformations fall short of their objectives,
              often with profound consequences.
            </FancyQuote>
            <p>Failure happens a lot, with heavy consequences:</p>
            <FancyQuote
              author="Bent Flyvbjerg and Alexander Budzier"
              cite={
                <>
                  <Link to="https://hbr.org/2011/09/why-your-it-project-may-be-riskier-than-you-think">
                    Why Your IT Project May Be Riskier Than You Think
                  </Link>
                  . HBR, 2011
                </>
              }
            >
              The average overrun was 27%. Fully one in six of the projects we
              studied was a black swan, with a cost overrun of 200%, on average,
              and a schedule overrun of almost 70%.
            </FancyQuote>
            <p>
              Sometimes, consequences are worse than just a project overrun:
            </p>
            <FancyQuote
              author="Michael Bloch, Sven Blumberg, and Jürgen Laartz"
              cite={
                <>
                  <Link to="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/delivering-large-scale-it-projects-on-time-on-budget-and-on-value#:~:text=17%20,existence%20of%20the%20company">
                    Delivering large-scale IT projects on time, on budget, and
                    on value
                  </Link>
                  . McKinsey, 2012
                </>
              }
            >
              17% of IT projects go so bad that they can threaten the very
              existence of the company.
            </FancyQuote>
          </>
        </Drawer>
        <p>It&apos;s not IT projects only:</p>
        <FancyQuote
          author="KPMG, AIPM and IPMA"
          cite={
            <>
              <Link to="https://www.ipma.world/assets/PM-Survey-FullReport-2019-FINAL.pdf#page=7">
                The future of project management
              </Link>
              . 2019
            </>
          }
        >
          <ul>
            <li>
              81% of organizations fail to regularly deliver successful
              projects.
            </li>
            <li>70% are unlikely to deliver projects on time.</li>
            <li>64% are unlikely to deliver projects on budget.</li>
            <li>
              56% are unlikely to deliver projects that meet original goal and
              intent.
            </li>
          </ul>
        </FancyQuote>
        <Separator />
        <h2>Main causes of failure are known</h2>
        <p>
          In 2009, a IDC report
          <Anchor.Note id="2" /> already noticed an interesting fact about
          failed IT projects:
        </p>
        <FancyQuote
          author="Joseph&nbsp;Gulla"
          cite={
            <>
              <Link to="https://web.archive.org/web/20201019111214/https://ibmsystemsmag.com/Power-Systems/2/2012/7-reasons-it-projects-fail#:~:text=By%20categorizing%20documented,at%203%20percent">
                7 Reasons IT Projects Fail
              </Link>
              . IBM&nbsp;Systems, 2012
            </>
          }
        >
          Surprisingly to some, technical challenges are the least-cited factor
          at 3%.
        </FancyQuote>
        <p>
          One might think technical projects fail because of technical
          challenges, or because of a lack of technical skills. But it&apos;s
          wrong, most of the time.
        </p>
        <p>
          According to the{' '}
          <Link to="https://www.pmi.org/-/media/pmi/documents/public/pdf/learning/thought-leadership/pulse/pmi-pulse-2021-appendix.pdf#page=11">
            PMI Pulse of the Profession 2021 report
          </Link>
          , primary causes of project failures are:{' '}
        </p>
        <DrawerContainer event={drawerEvent1} open>
          <ul>
            <LiBar $width={40}>Poor requirements gathering</LiBar>
            <LiBar $width={33}>Poor upfront planning</LiBar>
            <LiBar $width={27}>Ineffective risk management</LiBar>
            <LiBar $width={24}>Poor change management</LiBar>
            <LiBar $width={23}>Poorly managed dependencies</LiBar>
          </ul>
        </DrawerContainer>
        <DrawerContainer event={drawerEvent1}>
          <ul>
            <LiBar $width={41}>Change in organization&apos;s priorities</LiBar>
            <LiBar $width={40}>Poor requirements gathering</LiBar>
            <LiBar $width={38}>Change in project objectives</LiBar>
            <LiBar $width={33}>Poor upfront planning</LiBar>
            <LiBar $width={28}>Poor/Inadequate communication</LiBar>
            <LiBar $width={28}>Inadequate vision/goal for project</LiBar>
            <LiBar $width={27}>Ineffective risk management</LiBar>
            <LiBar $width={25}>Inadequate sponsor support</LiBar>
            <LiBar $width={24}>Poor change management</LiBar>
            <LiBar $width={23}>Poorly managed dependencies</LiBar>
            <LiBar $width={20}>Limited/taxed resources</LiBar>
            <LiBar $width={20}>Inexperienced project manager</LiBar>
            <LiBar $width={6}>Other mentions</LiBar>
            <li style={{ listStyle: 'none', marginLeft: 0 }}>
              <p>
                <small>
                  Q: What are the primary causes of project failures? (%
                  selected)
                </small>
              </p>
            </li>
          </ul>
        </DrawerContainer>
        <DrawerControl event={drawerEvent1}>
          <p>
            🧐 <span className="underline">Show full list</span>
          </p>
        </DrawerControl>
        <p>
          To achieve success, there&apos;s definitely many strategies to explore
          before adding more technical skills to a team.
        </p>
      </Section>

      <Section background={BackgroundColor.DARK} textAlign={TextAlign.CENTER}>
        <p>Most software development teams could tell you that:</p>
        <Headline>
          Technical projects don&apos;t fail because of technical challenges.
        </Headline>
      </Section>

      <Section>
        <h2>
          So how can I help you as a{' '}
          <span style={{ color: colors.C5 }}>technical&nbsp;advisor</span>?
        </h2>
        <p>
          <em>
            Especially if main causes of failure are not technical challenges.
          </em>
        </p>
        <p>
          For the past 13 years, I&apos;ve been working with frontend, backend,
          mobile and cloud technologies on a wide variety of projects, with
          budgets ranging from thousands to millions of&nbsp;$.
        </p>
        <h4 style={{ marginTop: 0 }}>
          As a technical advisor, I can help you start, plan, and successfully
          execute your software development project.
        </h4>
        <Separator />
        <h3>
          1. I can help you <span className="underline">start</span> your
          project
        </h3>
        <p>
          To set up conditions of success at the early stage of your project, I
          can help you:
        </p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Direct a business analysis process and a comprehensive
              requirements gathering process.
            </p>
            <p>
              Companies that succeed at delivering projects are the ones that
              use requirements gathering as a discovery process to define and
              understand the project scope from the beginning.
            </p>
            <FancyQuote
              author="Keith Ellis"
              cite={
                <>
                  <Link to="https://web.archive.org/web/20130917021904/http://www.iag.biz/images/resources/iag%20business%20analysis%20benchmark%20-%20full%20report.pdf">
                    Business Analysis Benchmark.
                  </Link>{' '}
                  IAG, 2008
                </>
              }
            >
              If your people and processes in business requirements are only
              AVERAGE, rather than excellent, this lack of excellence will
              consume approximately 41.5% of the IT development budget for
              people (internal and external) and software on strategic projects.
            </FancyQuote>
            <p>
              The image below is an example of a specification document produced
              during a requirements gathering phase:
            </p>
            <Image
              alt="Miro chart"
              caption="Modelling of user interactions through mobile application interfaces"
              src="mapping.webp"
              width={80}
            />
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Estimate features complexity, design a technical solution
              according to the project budget and schedule, and according to the
              development team capacity.
            </p>
            <p>
              Once the project functional scope has been defined with high-level
              functional specifications, the project schedule and technological
              scope can be drafted with rough estimates.
            </p>
            <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764516780541265&cot=14">
              <Image
                alt="T-shirt sizing estimates"
                caption='How to estimate with "t-shirt sizing"'
                src="tshirt-sizing-estimates.webp"
                width={90}
              />
            </Link>
          </li>
        </ul>
        <Separator />
        <h3>
          2. I can help you <span className="underline">plan</span> the
          execution phase of your project
        </h3>
        <p>
          Once functional requirements and rough estimates are known,
          you&apos;ll have a better idea of how much resources you need to
          execute your project. During this planning phase, I can help you:
        </p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Build the project roadmap and the production planning according to
              the available resources and technical dependencies.
            </p>
            <p>
              This step will establish the development team needs and the
              delivery milestones.
            </p>
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Identify risk, uncertainty, and define strategies to adapt the
              project roadmap.
            </p>
            <p>
              If targeted milestones cannot be reached, because of a lack of
              resources or a technical challenge, consequences have to be
              anticipated.
            </p>
          </li>
        </ul>
        <Separator />
        <h3>
          3. I can help you <span className="underline">execute</span> your
          project
        </h3>
        <p>Two things will inevitably happen in the course of your project:</p>
        <ul>
          <li>
            Some people will change their minds and they&apos;ll ask to change
            what has been decided and planned.
          </li>
          <li>Some people will leave the team, and others will join it.</li>
        </ul>
        <p> To execute your plan and manage change, I can help you:</p>
        <ul>
          <li>
            <p style={{ fontWeight: 600 }}>
              Set up an iterative software development life cycle that
              integrates requirements gathering and planning steps.
            </p>
            <p>
              This allows postponing critical decisions until the project scope
              is better understood. It also allows to continuously refine the
              project functional scope and planning, with the lessons learned
              from previous iterations.
            </p>
            <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764520753890444&cot=14">
              <Image
                alt="Software development life cycle"
                caption="Software development life cycle simplified"
                src="sdlc-circle.webp"
                width={90}
              />
            </Link>
          </li>
          <li>
            <p style={{ fontWeight: 600 }}>
              Define, document, and teach such software development life cycle
              to other team members.
            </p>
            <p>
              This will help to align practices and processes of the whole team,
              and quickly onboard new members.
            </p>
          </li>
        </ul>
        <p>
          <Link to="https://miro.com/app/board/o9J_leBQdDQ=/?moveToWidget=3458764516388272898&cot=14">
            <Image
              alt="Miro board"
              caption="Software development life cycle"
              src="sdlc.webp"
              width={90}
            />
          </Link>
        </p>
      </Section>
      <Section background={BackgroundColor.DARK} minHeight={5}>
        <Headline>
          I can help you make a plan and navigate through the complexity of a
          software development project.
        </Headline>
      </Section>
      <Section>
        <AboutMe>
          <CenterOnNarrowScreen>
            <h3 style={{ marginTop: rhythm(1) }}>Get in touch</h3>
            <p>
              If you want to know more about how I can help, reach me on{' '}
              <Link to="https://www.linkedin.com/in/jexperton/">LinkedIn</Link>{' '}
              or by email at{' '}
              <Email value="jonathan.experton@gmail.com" copied="copied!" />
            </p>
          </CenterOnNarrowScreen>
        </AboutMe>
        <Separator />
        <h2>
          Some of my latest articles about IT and software&nbsp;development
        </h2>
        <ArticlesList limit={4} filter={({ promoted }) => promoted} />
        <p>
          <Link to="/en/blog">Read more articles</Link>
        </p>
        <Separator />
        <Note id="1">
          If you need help to refine what you want or to shape your vision, I
          have <Link to="https://andfriends.ca/">friends</Link> who can
          help&nbsp;you.
        </Note>
        <Note id="2">
          I couldn&apos;t find a public link to this often cited 2009 IDC report
          &quot;Improving IT Project Outcomes by Systematically Managing and
          Hedging Risk&quot; by Dana Wiklund and Joseph C. Pucciarelli.
        </Note>
      </Section>
    </>
  );
}

export default Page;
